import { createSelector } from '@reduxjs/toolkit';
import { NotificationOptions } from 'core/models';

import { RootState } from '../setupStore';
import { NotificationState } from './notificationSlice';

/** Получаем значение поля notification для отображения уведомления */
export const getNotification = createSelector(
  (state: RootState) => state.notification,
  (notification: NotificationState) => notification,
);
