import React, { FC, ReactNode } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import 'react-quill/dist/quill.bubble.css';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';

export type Props = {
  title: string;
  /** Текст сообщения */
  content: string | null;
  open: boolean;
  onClose: () => void;
  /** В качестве children принимает кнопки */
  children: ReactNode;
};

/** Общая модалка
 * @param props пропсы компонента
 * @returns Общая модалка
 */
export const Modal: FC<Props> = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth
      maxWidth={'sm'}
    >
      <DialogTitle id='alert-dialog-title'>
        {props.title}
        <IconButton
          aria-label='close'
          onClick={props.onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {props.content}
        </DialogContentText>
      </DialogContent>
      {/** В качестве children принимает кнопки */}
      <DialogActions>{props.children}</DialogActions>
    </Dialog>
  );
};
