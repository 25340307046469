import React, { FC, ReactNode } from 'react';
import { WrapperNotification } from 'core';

import { ChoosePhotoCustomerHeader } from './ChoosePhotoCustomerHeader';
import { Box, Container } from '@mui/material';
import { ChoosePhotoCustomerFooter } from './ChoosePhotoCustomerFooter';

type Props = {
  /** контент сайта */
  children: ReactNode;
};

/**
 * Layout для отображения выбора фотографий ученика по этапам
 * @param props пропсы компонента
 * @returns Layout для отображения выбора фотографий ученика по этапам
 */
export const ChoosePhotoCustomerLayout: FC<Props> = (props) => {
  return (
    <WrapperNotification>
      <ChoosePhotoCustomerHeader />
      {/** Этот отсуп нужен, чтобы выровнять контент т.к у header стоит position fixed.
       * 64px это размер шапки
       */}
      <Box sx={{ mt: '94px', mb: '50px' }}>
        <Container>{props.children}</Container>
      </Box>
      <ChoosePhotoCustomerFooter />
    </WrapperNotification>
  );
};
