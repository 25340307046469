import {
  ChoosePhotoContainer,
  ChoosePhotoCustomerLayout,
  WrapperStepper,
} from 'modules/choosePhotoCustomer';
import React from 'react';

export const ChoosePhoto = () => {
  return (
    <ChoosePhotoCustomerLayout>
      <WrapperStepper>
        <ChoosePhotoContainer />
      </WrapperStepper>
    </ChoosePhotoCustomerLayout>
  );
};
