/** Формирует строку сколько нужно выбрать для перехода на следующий этап
 * @param choosedPhotoCount количество фотографий которые нужно выбрать для перехода на следующий этап
 * @returns string строка
 */
export const choosedPhotoCountGenerateString = (
  choosedPhotoCount: number | undefined,
): string | undefined => {
  /** Если значение количество фотографий которые нужно выбрать undefined или  равнятеся -1 или 0*/
  if (!choosedPhotoCount) {
    return '/–';
  }
  /** Если значение количество фотографий которые нужно выбрать имеет конкретное число */
  if (choosedPhotoCount > 0) {
    return `/${choosedPhotoCount}`;
  }
};
