import {
  ChoosePhotoCustomerLayout,
  ViewingSelectedPhotosContainer,
  WrapperStepper,
} from 'modules/choosePhotoCustomer';
import React, { FC } from 'react';

export const ViewingSelectedPhotos: FC = () => {
  return (
    <ChoosePhotoCustomerLayout>
      <WrapperStepper>
        <ViewingSelectedPhotosContainer />
      </WrapperStepper>
    </ChoosePhotoCustomerLayout>
  );
};
