import React from 'react';
import {
  ChoosePhotoCustomerLayout,
  СonfirmationNameContainer,
} from 'modules/choosePhotoCustomer';

/**
 * Страница выбора фото для заказчика с этапами
 * @returns Страница выбора фото для заказчика с этапами
 */
export const СonfirmationName = () => {
  return (
    <ChoosePhotoCustomerLayout>
      <СonfirmationNameContainer />
    </ChoosePhotoCustomerLayout>
  );
};
