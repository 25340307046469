import React, { FC } from 'react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Typography,
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import { getSelectedId } from 'modules/choosePhotoCustomer/store/selectors';
import { UseAssetResponseModel } from 'services/generated-api';

type Props = {
  item: UseAssetResponseModel;
  onCheckedPhoto?: (id: number) => void;
  onDeleteOnePhoto?: (id: number) => void;
  onOpenViewEnlargedPhoto?: (id: number) => void;
};

export const PhotoCard: FC<Props> = (props) => {
  const { item, onCheckedPhoto, onDeleteOnePhoto, onOpenViewEnlargedPhoto } =
    props;
  const selectedPhotoIds = useSelector(getSelectedId);

  return (
    <Card>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() =>
          onOpenViewEnlargedPhoto && onOpenViewEnlargedPhoto(item.id as number)
        }
      >
        <CardMedia
          component='img'
          image={`data:image/jpg;base64, ${item.fileBytes}`}
          alt={`${item.name}`}
        />
      </Box>

      <CardActions disableSpacing>
        {onCheckedPhoto && (
          <IconButton
            color={
              selectedPhotoIds.includes(item.id as number) ? 'error' : 'default'
            }
            onClick={() => onCheckedPhoto(item.id as number)}
          >
            <FavoriteIcon />
          </IconButton>
        )}
        {onDeleteOnePhoto && (
          <IconButton onClick={() => onDeleteOnePhoto(item.id as number)}>
            <DeleteIcon color='error' />
          </IconButton>
        )}
        <Typography variant='body2' color='text.secondary'>
          {item.fileName}
        </Typography>
      </CardActions>
    </Card>
  );
};
