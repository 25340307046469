import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
} from '@mui/material';
import { pink } from '@mui/material/colors';
import { PAGE_SIZE } from 'core/consts';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'core/hooks/redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import {
  getAlbumsInfinity,
  getEnlargedPhoto,
  nextLevelChoosePhoto,
  trustPhotographerPhotosForLevel,
} from 'modules/choosePhotoCustomer/store/actions';
import {
  getCheckedTrustLevelExecutor,
  disableScrollingSelector,
  getErrors,
  getNextLevelSelector,
  getEnlargedPhotoSelector,
} from 'modules/choosePhotoCustomer/store/selectors';
import { UseAssetResponseModel } from 'services/generated-api';
import { ViewEnlargedPhoto } from 'shared/modal';

import { PhotoCard } from './PhotoCard';
import { choosePhotoCustomerSlice } from '../../store';

type Props = {
  album: {
    totalCount: null | number;
    hasNextPage: boolean;
    photos: UseAssetResponseModel[];
    pageNumber: null | number;
  };
};

/** Компонент отображает список фотографий
 * @param props пропсы компонента
 * @returns список фотографий
 */
export const SelectPhotoList: FC<Props> = (props) => {
  const { album } = props;
  const checkedTrustLevelExecutor = useSelector(getCheckedTrustLevelExecutor);
  /** Параметр для блокирования вызова функции при скроле */
  const disableScrolling = useSelector(disableScrollingSelector);
  const enlargedPhoto = useSelector(getEnlargedPhotoSelector);
  /** Получаем ошибки запросов */
  const errors = useSelector(getErrors);
  const nextLevelSelector = useSelector(getNextLevelSelector);
  const [page, setPage] = useState(album.pageNumber || 1);
  const [openModal, setOpenModal] = useState(false);

  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const scrollRef = useRef(false);

  /** Обрабочтик клика по чекбоксу
   * @param event ChangeEvent<HTMLInputElement>
   * @returns void
   */
  const handleChange = () => {
    dispatch(
      choosePhotoCustomerSlice.choosePhotoCustomerSlice.actions.setCheckedTrustLevelExecutor(
        !checkedTrustLevelExecutor,
      ),
    );
  };

  /** Добавляет id выбранного фото в state и localStorage
   * @param id id выбранного фото
   * @returns void
   */
  const handleCheckedPhoto = (id: number) => {
    dispatch(
      choosePhotoCustomerSlice.choosePhotoCustomerSlice.actions.addPhotoId(id),
    );
  };

  /** Получаем обновленный список фотогафий
   * @returns void
   */
  const handleOnRowsScroll = (): void => {
    /** Если есть еще фото на сервере (album.hasNextPage), то отправляем запрос */
    if (nextLevelSelector?.id && album.hasNextPage && scrollRef.current) {
      dispatch(
        getAlbumsInfinity({
          levelId: nextLevelSelector.id,
          pageSize: PAGE_SIZE,
          pageNumber: page,
        }),
      );
    }
  };

  /** увеличиваем страницу на 1
   * затем вызваем useEffect, который вызываем функцию handleOnRowsScroll
   * @returns   void
   */
  const handleLoadMore = (): void => {
    if (disableScrolling) setPage((page) => page + 1);
  };

  /** Переходим на следующий этап выбора.
   * Функция срабатывает если пользователь выбрал чекбокс доверяю выбор фотографу
   * @returns void
   */
  const handleNextLevel = () => {
    if (nextLevelSelector?.id && params.albumGuid) {
      dispatch(
        trustPhotographerPhotosForLevel({
          albumGuid: params.albumGuid,
          levelId: nextLevelSelector.id,
        }),
      );
    }
  };

  const handleCloseViewEnlargedPhoto = (): void => {
    setOpenModal(false);
    dispatch(
      choosePhotoCustomerSlice.choosePhotoCustomerSlice.actions.resetEnlargedPhoto(),
    );
  };

  const handleOpenViewEnlargedPhoto = (id: number) => {
    dispatch(getEnlargedPhoto([id]));
    setOpenModal(Boolean(id));
  };

  useEffect(() => {
    handleOnRowsScroll();
  }, [page]);

  useEffect(() => {
    if (params.albumGuid && nextLevelSelector?.isComplited !== false) {
      dispatch(nextLevelChoosePhoto(params.albumGuid));
    }
  }, []);

  useEffect(() => {
    /** Ошибка может быть вызвана, непройденных этапов больше нет
     * Поэтому перенаправляем на финальную страницу просмотра выбранных фотографий
     * Делаем задержку, т.к отображаем уведомление для пользователя о том что все этапы пройдены
     */
    if (errors) {
      setTimeout(() => {
        navigate(`/final-level/${params.albumGuid}/${params.customerId}`);
      }, 5000);
    }
  }, [errors]);

  useEffect(() => {
    const handleScroll = () => {
      scrollRef.current = true;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <ViewEnlargedPhoto
        onClose={handleCloseViewEnlargedPhoto}
        open={openModal}
        enlargedPhoto={enlargedPhoto}
        setOpenModal={setOpenModal}
      />

      <FormGroup sx={{ mt: '18px' }}>
        <FormControlLabel
          value='photographer'
          control={
            <Checkbox
              sx={{
                color: pink[800],
                '&.Mui-checked': {
                  color: pink[600],
                },
              }}
              onChange={handleChange}
              checked={checkedTrustLevelExecutor}
            />
          }
          label='Доверяю фотографу выбор фото на этом этапе'
          sx={{
            color: pink[800],
          }}
        />
      </FormGroup>
      {!checkedTrustLevelExecutor ? (
        <InfiniteScroll
          dataLength={album?.photos?.length}
          next={handleLoadMore}
          hasMore={album.hasNextPage}
          scrollThreshold={'50px'}
          style={{
            overflow: 'unset',
          }}
          loader={
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          }
        >
          <Grid
            sx={{
              display: 'grid',
              gap: 2,
              gridTemplateColumns: 'repeat(auto-fill, 220px)',
            }}
          >
            {album.photos?.map((item) => (
              <PhotoCard
                key={item.id}
                item={item}
                onCheckedPhoto={handleCheckedPhoto}
                onOpenViewEnlargedPhoto={handleOpenViewEnlargedPhoto}
              />
            ))}
          </Grid>
        </InfiniteScroll>
      ) : (
        <Button onClick={handleNextLevel} variant='contained' color='error'>
          Перейти на следующий этап
        </Button>
      )}
    </>
  );
};
