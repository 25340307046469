/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssetType } from '../models/AssetType';
import type { ChooseAssetArrayCustomerCommand } from '../models/ChooseAssetArrayCustomerCommand';
import type { ChooseAssetCustomerCommand } from '../models/ChooseAssetCustomerCommand';
import type { CustomerAlbumTrustExecutorCommand } from '../models/CustomerAlbumTrustExecutorCommand';
import type { CustomerLevelTrustExecutorCommand } from '../models/CustomerLevelTrustExecutorCommand';
import type { ImageSize } from '../models/ImageSize';
import type { SetLevelComplitedStatusCommand } from '../models/SetLevelComplitedStatusCommand';
import type { UnchooseAssetArrayCustomerCommand } from '../models/UnchooseAssetArrayCustomerCommand';
import type { UnchooseAssetCustomerCommand } from '../models/UnchooseAssetCustomerCommand';
import type { UpdateCustomerNameCommand } from '../models/UpdateCustomerNameCommand';
import type { UseAlbumResponseModel } from '../models/UseAlbumResponseModel';
import type { UseAssetResponseModelPaginatedList } from '../models/UseAssetResponseModelPaginatedList';
import type { UseChoosedAssetsResponseModel } from '../models/UseChoosedAssetsResponseModel';
import type { UseCustomerResponseModel } from '../models/UseCustomerResponseModel';
import type { UseCustomerResponseModelPaginatedList } from '../models/UseCustomerResponseModelPaginatedList';
import type { UseLevelResponseModel } from '../models/UseLevelResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UseAssetsService {

    /**
     * Получить альбом по guid
     * Не нужна авторизация
     * @returns UseAlbumResponseModel Success
     * @throws ApiError
     */
    public static getApiUseAssetsAlbum({
guid,
}: {
/**
 * Идентификатор альбома
 */
guid: string,
}): CancelablePromise<UseAlbumResponseModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/UseAssets/album/{guid}',
            path: {
                'guid': guid,
            },
        });
    }

    /**
     * Получить список заказчиков альбома
     * Не нужна авторизация
     * @returns UseCustomerResponseModelPaginatedList Success
     * @throws ApiError
     */
    public static getApiUseAssetsCustomers({
albumGuid,
}: {
albumGuid?: string,
}): CancelablePromise<UseCustomerResponseModelPaginatedList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/UseAssets/customers',
            query: {
                'AlbumGuid': albumGuid,
            },
        });
    }

    /**
     * Получить заказчика по Id
     * Не нужна авторизация
     * @returns UseCustomerResponseModel Success
     * @throws ApiError
     */
    public static getApiUseAssetsCustomer({
id,
}: {
id?: number,
}): CancelablePromise<UseCustomerResponseModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/UseAssets/customer',
            query: {
                'Id': id,
            },
        });
    }

    /**
     * Получить список этапов альбома
     * Нужна авторизация в роли Customer
     * @returns UseLevelResponseModel Success
     * @throws ApiError
     */
    public static getApiUseAssetsLevels({
albumGuid,
}: {
albumGuid?: string,
}): CancelablePromise<Array<UseLevelResponseModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/UseAssets/levels',
            query: {
                'AlbumGuid': albumGuid,
            },
        });
    }

    /**
     * Получить следующий этап альбома
     * Нужна авторизация в роли Customer
     * @returns UseLevelResponseModel Success
     * @throws ApiError
     */
    public static getApiUseAssetsNextLevel({
albumGuid,
}: {
albumGuid?: string,
}): CancelablePromise<UseLevelResponseModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/UseAssets/next-level',
            query: {
                'AlbumGuid': albumGuid,
            },
        });
    }

    /**
     * Получить список ресурсов
     * Нужна авторизация в роли Customer
     * @returns UseAssetResponseModelPaginatedList Success
     * @throws ApiError
     */
    public static getApiUseAssetsAssets({
ids,
names,
type,
levelId,
imageSize,
pageNumber,
pageSize,
}: {
ids?: Array<number>,
names?: Array<string>,
type?: AssetType,
levelId?: number,
imageSize?: ImageSize,
pageNumber?: number,
pageSize?: number,
}): CancelablePromise<UseAssetResponseModelPaginatedList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/UseAssets/assets',
            query: {
                'Ids': ids,
                'Names': names,
                'Type': type,
                'LevelId': levelId,
                'ImageSize': imageSize,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
            },
        });
    }

    /**
     * Получить файл
     * Нужна авторизация в роли Customer
     * @returns any Success
     * @throws ApiError
     */
    public static getApiUseAssetsFile({
assetId,
imageSize,
}: {
assetId?: number,
imageSize?: ImageSize,
}): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/UseAssets/file',
            query: {
                'AssetId': assetId,
                'ImageSize': imageSize,
            },
        });
    }

    /**
     * Получить список выбранных ресурсов (фото)
     * Нужна авторизация в роли Customer
     * @returns UseChoosedAssetsResponseModel Success
     * @throws ApiError
     */
    public static getApiUseAssetsChoosed({
albumGuid,
includeFiles,
}: {
albumGuid?: string,
includeFiles?: boolean,
}): CancelablePromise<Array<UseChoosedAssetsResponseModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/UseAssets/choosed',
            query: {
                'AlbumGuid': albumGuid,
                'IncludeFiles': includeFiles,
            },
        });
    }

    /**
     * Завершение этапа альбома
     * Нужна авторизация в роли Customer
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiUseAssetsLevelComplite({
requestBody,
}: {
requestBody?: SetLevelComplitedStatusCommand,
}): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/UseAssets/level-complite',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Выбрать фото
     * Нужна авторизация в роли Customer
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiUseAssetsChoose({
requestBody,
}: {
requestBody?: ChooseAssetCustomerCommand,
}): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/UseAssets/choose',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Выбрать массив фото
     * Нужна авторизация в роли Customer
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiUseAssetsChooseArray({
requestBody,
}: {
requestBody?: ChooseAssetArrayCustomerCommand,
}): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/UseAssets/choose-array',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Удалить выбор фото
     * Нужна авторизация в роли Customer
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiUseAssetsUnchoose({
requestBody,
}: {
requestBody?: UnchooseAssetCustomerCommand,
}): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/UseAssets/unchoose',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Удалить выбор фото по массивe Id
     * Нужна авторизация в роли Customer
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiUseAssetsUnchooseArray({
requestBody,
}: {
requestBody?: UnchooseAssetArrayCustomerCommand,
}): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/UseAssets/unchoose-array',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Установить - Доверяю выбор фотографу для этапа
     * Нужна авторизация в роли Customer
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiUseAssetsTrustLevelExecutor({
requestBody,
}: {
requestBody?: CustomerLevelTrustExecutorCommand,
}): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/UseAssets/trust-level-executor',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Установить - Доверяю выбор фотографу для всего альбома
     * Нужна авторизация в роли Customer
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiUseAssetsTrustAlbumExecutor({
requestBody,
}: {
requestBody?: CustomerAlbumTrustExecutorCommand,
}): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/UseAssets/trust-album-executor',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Сохранить изменение ФИО заказчика. Реальное ФИО не меняется
     * Нужна авторизация в роли Customer
     * @returns boolean Success
     * @throws ApiError
     */
    public static putApiUseAssetsChangeCustomerName({
requestBody,
}: {
requestBody?: UpdateCustomerNameCommand,
}): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/UseAssets/change-customer-name',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
