import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import {
  getEnlargedPhotoSelector,
  getNextLevelSelector,
  getOptionsForInfiniteScroll,
  getSelectedId,
  getSelectedOptions,
  getViewingSelectedPhotos,
} from 'modules/choosePhotoCustomer/store/selectors';
import { useAppDispatch } from 'core/hooks/redux';
import {
  getAlbumsForViewingSelectedPhotos,
  getEnlargedPhoto,
} from 'modules/choosePhotoCustomer/store/actions';
import { PAGE_SIZE } from 'core/consts';
import { ViewEnlargedPhoto } from 'shared/modal';
import { choosePhotoCustomerSlice } from 'modules/choosePhotoCustomer/store';

import { PhotoCard } from '../choosePhoto/PhotoCard';

export const ViewingSelectedPhotosContainer = () => {
  const viewingSelectedPhotos = useSelector(getViewingSelectedPhotos);
  const optionsForInfiniteScroll = useSelector(getOptionsForInfiniteScroll);
  const selectedPhotoIds = useSelector(getSelectedId);
  const nextLevelSelector = useSelector(getNextLevelSelector);
  const selectedOptions = useSelector(getSelectedOptions);
  const enlargedPhoto = useSelector(getEnlargedPhotoSelector);

  const [page, setPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);

  const dispatch = useAppDispatch();

  const handleCloseViewEnlargedPhoto = (): void => {
    setOpenModal(false);
    dispatch(
      choosePhotoCustomerSlice.choosePhotoCustomerSlice.actions.resetEnlargedPhoto(),
    );
  };

  const handleOpenViewEnlargedPhoto = (id: number) => {
    setOpenModal(Boolean(id));
    dispatch(getEnlargedPhoto([id]));
  };

  const handleOnRowsScroll = (): void => {
    /** Если есть есть еще фото на сервере (album.hasNextPage), то отправляем запрос */
    if (optionsForInfiniteScroll.hasNextPage && page > 1) {
      dispatch(
        getAlbumsForViewingSelectedPhotos({
          pageSize: PAGE_SIZE,
          pageNumber: page,
          ids: selectedPhotoIds,
        }),
      );
    }
  };

  /** увеличиваем страницу на 1
   * затем вызваем useEffect, который вызываем функцию handleOnRowsScroll
   * @returns void
   */
  const handleLoadMore = (): void => {
    viewingSelectedPhotos.length && setPage((page) => page + 1);
  };

  /** Функци удаляет фотографию из стора и localStorage
   * @param id id фотографии
   * @returns void
   */
  const handleDeleteOnePhoto = (id: number): void => {
    dispatch(
      choosePhotoCustomerSlice.choosePhotoCustomerSlice.actions.removePhoto(id),
    );
  };

  useEffect(() => {
    handleOnRowsScroll();
  }, [page]);

  useEffect(() => {
    dispatch(
      getAlbumsForViewingSelectedPhotos({
        pageSize: PAGE_SIZE,
        pageNumber: 1,
        ids: selectedPhotoIds,
      }),
    );

    return () => {
      dispatch(
        choosePhotoCustomerSlice.choosePhotoCustomerSlice.actions.resetViewingSelectedPhotos(),
      );
    };
  }, []);

  return (
    <>
      <ViewEnlargedPhoto
        onClose={handleCloseViewEnlargedPhoto}
        open={openModal}
        enlargedPhoto={enlargedPhoto}
        setOpenModal={setOpenModal}
      />
      <Typography variant='h3' mt={'20px'}>
        Предварительный просмотр фотографий. Для этапа:{' '}
        {nextLevelSelector?.name}
      </Typography>
      <Typography component='div'>
        Выбрано: {selectedOptions.countSelectedPhoto} шт.
      </Typography>
      <Typography component='div' mb={'20px'}>
        Нужно выбрать:{' '}
        {selectedOptions.choosedPhotoCount &&
        selectedOptions.choosedPhotoCount > 0
          ? `${selectedOptions.choosedPhotoCount} шт.`
          : 'любое количество'}
      </Typography>
      <InfiniteScroll
        dataLength={viewingSelectedPhotos?.length}
        next={handleLoadMore}
        hasMore={optionsForInfiniteScroll.hasNextPage}
        scrollThreshold={'50px'}
        style={{
          overflow: 'unset',
        }}
        loader={
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        }
      >
        <Grid
          sx={{
            display: 'grid',
            gap: 2,
            gridTemplateColumns: 'repeat(auto-fill, 220px)',
          }}
        >
          {viewingSelectedPhotos.map((item) => (
            <PhotoCard
              key={item.id}
              item={item}
              onDeleteOnePhoto={handleDeleteOnePhoto}
              onOpenViewEnlargedPhoto={handleOpenViewEnlargedPhoto}
            />
          ))}
        </Grid>
      </InfiniteScroll>
    </>
  );
};
