import React, { useEffect, FC, ReactNode } from 'react';
import { BottomNavigation, BottomNavigationAction, Box, Skeleton, Step, StepLabel, Stepper } from '@mui/material';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'core/hooks/redux';
import {
  getCustomer,
  getLevels,
} from 'modules/choosePhotoCustomer/store/actions';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getDoneAllLevels,
  getLevelSelector,
  getNextLevelSelector,
} from 'modules/choosePhotoCustomer/store/selectors';
import { UseLevelResponseModel } from 'services/generated-api';

type Props = {
  children: ReactNode;
};

export const WrapperStepper: FC<Props> = ({ children }) => {
  const levels = useSelector(getLevelSelector);
  const doneAllLevels = useSelector(getDoneAllLevels);

  const nextLevelSelector = useSelector(getNextLevelSelector);

  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const activeStep = levels?.findIndex(
    (level: UseLevelResponseModel) => !level.isComplited,
  );

  /** Получаем все этапы */
  useEffect(() => {
    params.albumGuid && dispatch(getLevels(params.albumGuid));
  }, [nextLevelSelector]);

  /** Если все шаги завершены, то делаем переход на финальная страницу просмотра фотографий */
  useEffect(() => {
    if (doneAllLevels) {
      navigate(`/final-level/${params.albumGuid}/${params.customerId}`);
    }
  }, [doneAllLevels]);

  /** Получаем все заказчика */
  useEffect(() => {
    params.customerId &&
      dispatch(getCustomer({ id: Number(params.customerId) }));
  }, []);

  return (
    <>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {levels ? (
            levels.map((level) => (
              <Step key={level.name}>
                <StepLabel>{level.name}</StepLabel>
              </Step>
            ))
          ) : (
            <Skeleton variant='rectangular' width={'100%'} height={60} />
          )}
        </Stepper>
      </Box>
      {children}
    </>
  );
};
