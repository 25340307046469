import React, { FC } from 'react';
import { Alert, Box, Skeleton, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import {
  getAlbum,
  getCustomerSelector,
  getNextLevelSelector,
} from 'modules/choosePhotoCustomer/store/selectors';

import { SelectPhotoList } from './SelectPhotoList';

export const ChoosePhotoContainer: FC = () => {
  const customer = useSelector(getCustomerSelector);
  const album = useSelector(getAlbum);
  const nextLevelSelector = useSelector(getNextLevelSelector);

  return (
    <>
      <Typography variant='h3' mb={'6px'} mt={'40px'}>
        {customer ? (
          `${customer.name}`
        ) : (
          <Skeleton variant='rectangular' width={'40%'} />
        )}
        {customer && customer.userChangedName
          ? ` (${customer.userChangedName})`
          : ''}
      </Typography>

      <Typography variant='h3' mb={'20px'}>
        {nextLevelSelector ? (
          `Этап: ${nextLevelSelector.name}`
        ) : (
          <Skeleton variant='rectangular' width={'40%'} />
        )}
      </Typography>

      <Alert severity='warning'>
        {nextLevelSelector?.choosedPhotoCount === -1 ? (
          <>
            <Typography sx={{ fontSize: '14px' }}>
              На странице&nbsp;
              {album?.photos.length || '-'} фото. Выберите столько фото, сколько считаете нужным.
            </Typography>
            <Box>
              {`Вы можете отметить сердечком несколько фотографий, далее перейти в раздел "Посмотреть выбранные".
               Сравнить выбранные фотографии и удалить не подходящие.`}
            </Box>
          </>
        ) : (
          <>
            <Typography sx={{ fontSize: '14px' }}>
              Нужно выбрать {nextLevelSelector?.choosedPhotoCount || '-'} фото
              из&nbsp;
              {album?.totalCount || '-'} шт
            </Typography>
            <Box>
                  {`Вы можете отметить сердечком несколько фотографий, далее перейти в раздел "Посмотреть выбранные". Сравнить выбранные фотографии и удалить не подходящие. `}
            </Box>
          </>
        )}

            <Box>
              {`Если не выбрано указанное количество фото, кнопка "Перейти на следующий этап" будет не активна.`}
            </Box>
      </Alert>
      <Alert severity='success' sx={{ marginTop: '20px'}}>
            <Box>
                  {nextLevelSelector?.description}
            </Box>
      </Alert>
      <SelectPhotoList album={album} />
    </>
  );
};
