/* eslint-disable max-lines */
import {
  EntityState,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import {
  UseAlbumResponseModel,
  UseAssetResponseModel,
  UseChoosedAssetsResponseModel,
  UseCustomerResponseModel,
  UseLevelResponseModel,
} from 'services/generated-api';

import {
  choosePhotosFinalLevel,
  getAlbums,
  getAlbumsForViewingSelectedPhotos,
  getCustomer,
  getCustomers,
  getLevels,
  trustPhotographerAllPhotos,
  levelCompleted,
  nextLevelChoosePhoto,
  updateNameCustomer,
  getAlbumInfo,
  getAlbumsInfinity,
  getEnlargedPhoto,
} from './actions';

interface ChoosePhotoCustomerState {
  /** Загрузчик */
  isLoading: boolean;
  /** Загрузчик для финальной стрницы */
  isLoadingFinalLevel: boolean;
  /** Список учеников */
  customers: UseCustomerResponseModel[] | null;
  /** Текущий (выбранный) ученик */
  customer: UseCustomerResponseModel | null;
  /** Следующий этап */
  nextLevel: UseLevelResponseModel | null;
  /** Выбранные id фотографий для отображении в "просмотр выбранных фотографий" */
  selectedPhotoIds: number[];
  /** Информация для этапа */
  album: {
    /** Всего количество фотографий */
    totalCount: null | number;
    /** Текущая страница */
    pageNumber: null | number;
    /** Есть ли еще страница */
    hasNextPage: boolean;
    /** Все фотографии этапа */
    photos: EntityState<RequiredModel>;
  };
  doneAllLevels: boolean;
  /** Выбранные фотографии со всех этапов. Для просмотра на финальной страницы */
  choosePhotosFinalLevel: null | UseChoosedAssetsResponseModel[];
  /** Все этапы */
  levels: UseLevelResponseModel[] | null;
  checkedTrustLevelExecutor: boolean;
  /** Информаци о текущем альбоме */
  albumInfo: null | UseAlbumResponseModel;
  /** Информаци для страницы "просмотр выбранных фотографий" */
  viewingSelectedPhotos: {
    /** Количество выбранных фотографий */
    totalCount: null | number;
    /** Есть ли еще страница */
    hasNextPage: boolean;
    /** Выбранные фотографии */
    photos: EntityState<RequiredModel>;
  };
  disableScrolling: boolean;
  /** Ошибки запроса данных */
  errors: null | { name: string; message: string };
  /** Увеличенное изображение фото(оригинал) */
  enlargedPhoto: {
    photo: UseAssetResponseModel[] | null;
    isLoading: boolean;
  };
}

/** В модели AssetResponseModel делаем все поля обязательными */
type RequiredModel = {
  [K in keyof UseAssetResponseModel]-?: UseAssetResponseModel[K];
};

export const viewingSelectedPhotosAdapter = createEntityAdapter<RequiredModel>({
  selectId: (photo) => photo.id,
});

export const albumAdapter = createEntityAdapter<RequiredModel>({
  selectId: (item) => item.id,
});

const selectedPhotoIdsStr = localStorage.getItem('selectedPhotoIds');
const selectedPhotoIds =
  selectedPhotoIdsStr !== null ? JSON.parse(selectedPhotoIdsStr) : [];

export const initialState: ChoosePhotoCustomerState = {
  /** Загрузчик */
  isLoading: false,
  /** Загрузчик для финальной стрницы */
  isLoadingFinalLevel: false,
  /** Список учеников */
  customers: null,
  /** Текущий (выбранный) ученик */
  customer: null,
  /** Следующий этап */
  nextLevel: null,
  /** Выбранные id фотографий для отображении в "просмотр выбранных фотографий" */
  selectedPhotoIds: selectedPhotoIds,
  disableScrolling: true,
  /** Информация для этапа */
  album: {
    /** Всего колличество фотографий */
    totalCount: null,
    /** Текущая страница */
    pageNumber: null,
    /** Есть ли еще страница */
    hasNextPage: true,
    /** Все фотографии этапа */
    photos: albumAdapter.getInitialState(),
  },
  /** Выбранные фотографии со всех этапов. Для просмотра на финальной страницы */
  choosePhotosFinalLevel: null,
  /** Все этапы */
  levels: null,
  doneAllLevels: false,
  checkedTrustLevelExecutor: false,
  /** Информаци о текущем альбоме */
  albumInfo: null,
  /** Информаци для страницы "просмотр выбранных фотографий" */
  viewingSelectedPhotos: {
    /** Количество выбранных фотографий */
    totalCount: null,
    /** Есть ли еще страница */
    hasNextPage: true,
    /** Выбранные фотографии */
    photos: viewingSelectedPhotosAdapter.getInitialState(),
  },
  /** Ошибки запроса данных */
  errors: null,
  /** Увеличенное Фото для отображения в модалке */
  enlargedPhoto: {
    photo: null,
    isLoading: false,
  },
};

export const choosePhotoCustomerSlice = createSlice({
  name: 'choosePhotoCustomer',
  initialState,
  reducers: {
    addPhotoId: (state, { payload }) => {
      let newPhotoIds: number[] = [...state.selectedPhotoIds];
      const index = newPhotoIds.some((id) => id === payload);

      if (index) newPhotoIds = newPhotoIds.filter((id) => id !== payload);
      if (!index) newPhotoIds.push(payload);

      if (!localStorage.getItem('selectedPhotoIds')) {
        localStorage.setItem('selectedPhotoIds', JSON.stringify(newPhotoIds));
        state.selectedPhotoIds = newPhotoIds;
        return;
      }

      localStorage.setItem('selectedPhotoIds', JSON.stringify(newPhotoIds));
      state.selectedPhotoIds = newPhotoIds;
    },

    disableScrolling: (state) => {
      state.disableScrolling = false;
    },
    resetDataForCurrentLevel: (state) => {
      localStorage.removeItem('selectedPhotoIds');
      state.selectedPhotoIds = [];
      state.album = {
        totalCount: null,
        pageNumber: null,
        hasNextPage: true,
        photos: albumAdapter.getInitialState(),
      };
      state.viewingSelectedPhotos = {
        totalCount: null,
        hasNextPage: true,
        photos: viewingSelectedPhotosAdapter.getInitialState(),
      };
      // state.nextLevel = null;
    },
    resetViewingSelectedPhotos: (state) => {
      state.viewingSelectedPhotos = {
        totalCount: null,
        hasNextPage: true,
        photos: viewingSelectedPhotosAdapter.getInitialState(),
      };
    },
    resetCustomer: (state) => {
      state.customer = null;
    },
    removePhoto: (state, { payload }) => {
      const ids = state.selectedPhotoIds.filter((id: number) => id !== payload);

      localStorage.setItem('selectedPhotoIds', JSON.stringify(ids));
      state.selectedPhotoIds = ids;
      viewingSelectedPhotosAdapter.removeOne(
        state.viewingSelectedPhotos.photos,
        payload,
      );
    },
    setCheckedTrustLevelExecutor: (state, { payload }) => {
      state.checkedTrustLevelExecutor = payload;
    },
    resetEnlargedPhoto: (state) => {
      state.enlargedPhoto = {
        photo: null,
        isLoading: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCustomers.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.customers = payload.items as UseCustomerResponseModel[];
    });
    builder.addCase(getCustomers.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getCustomer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCustomer.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.customer = payload;
    });
    builder.addCase(getCustomer.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(updateNameCustomer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateNameCustomer.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateNameCustomer.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(nextLevelChoosePhoto.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(nextLevelChoosePhoto.fulfilled, (state, { payload }) => {
      state.nextLevel = payload;
      state.isLoading = false;
    });
    builder.addCase(nextLevelChoosePhoto.rejected, (state) => {
      state.errors = { name: 'nextLevel', message: 'Все этапы пройдены' };
      state.isLoading = false;
    });

    builder.addCase(getAlbums.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAlbums.fulfilled, (state, { payload }: any) => {
      if (payload.items) {
        state.disableScrolling = true;
        albumAdapter.setAll(state.album.photos, payload.items);
      }
      state.album.hasNextPage = payload.hasNextPage as boolean;
      state.album.pageNumber = payload.pageNumber as number;
      state.album.totalCount = payload.totalCount as number;
      state.isLoading = false;
    });
    builder.addCase(getAlbums.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getAlbumsInfinity.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAlbumsInfinity.fulfilled, (state, { payload }: any) => {
      if (payload.items) {
        albumAdapter.addMany(state.album.photos, payload.items);
      }
      state.album.hasNextPage = payload.hasNextPage as boolean;
      state.album.totalCount = payload.totalCount as number;
      state.album.pageNumber = payload.pageNumber as number;
      state.isLoading = false;
    });
    builder.addCase(getAlbumsInfinity.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getAlbumsForViewingSelectedPhotos.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getAlbumsForViewingSelectedPhotos.fulfilled,
      (state, { payload }: any) => {
        if (payload.items) {
          viewingSelectedPhotosAdapter.addMany(
            state.viewingSelectedPhotos.photos,
            payload.items,
          );
          state.viewingSelectedPhotos.hasNextPage =
            payload.hasNextPage as boolean;
          state.viewingSelectedPhotos.totalCount = payload.totalCount as number;
        }
        state.isLoading = false;
      },
    );
    builder.addCase(getAlbumsForViewingSelectedPhotos.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(choosePhotosFinalLevel.pending, (state) => {
      state.isLoadingFinalLevel = true;
    });
    builder.addCase(choosePhotosFinalLevel.fulfilled, (state, { payload }) => {
      state.choosePhotosFinalLevel = payload;
      state.isLoadingFinalLevel = false;
    });
    builder.addCase(choosePhotosFinalLevel.rejected, (state) => {
      state.isLoadingFinalLevel = false;
    });

    builder.addCase(getLevels.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLevels.fulfilled, (state, { payload }) => {
      state.levels = payload;
      state.isLoading = false;
    });
    builder.addCase(getLevels.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(levelCompleted.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(levelCompleted.fulfilled, (state, { payload }) => {
      if (payload) state.doneAllLevels = payload;
      state.isLoading = false;
    });
    builder.addCase(levelCompleted.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(trustPhotographerAllPhotos.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      trustPhotographerAllPhotos.fulfilled,
      (state, { payload }) => {
        if (payload) state.doneAllLevels = payload;
        state.isLoading = false;
      },
    );
    builder.addCase(trustPhotographerAllPhotos.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getAlbumInfo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAlbumInfo.fulfilled, (state, { payload }) => {
      state.albumInfo = payload;
      state.isLoading = false;
    });
    builder.addCase(getAlbumInfo.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getEnlargedPhoto.pending, (state) => {
      state.enlargedPhoto.isLoading = true;
    });
    builder.addCase(getEnlargedPhoto.fulfilled, (state, { payload }) => {
      state.enlargedPhoto.photo = payload.items as UseAssetResponseModel[];
      state.enlargedPhoto.isLoading = false;
    });
    builder.addCase(getEnlargedPhoto.rejected, (state) => {
      state.enlargedPhoto.isLoading = false;
    });
  },
});
export default choosePhotoCustomerSlice.reducer;
