import React, { FC, useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import {
  getAlbumName,
  getCustomerSelector,
  getEnlargedPhotoSelector,
  getFinalLevelData,
  getIsLoadingFinalLevelSelector,
  getLevelsMap,
} from 'modules/choosePhotoCustomer/store/selectors';
import { useAppDispatch } from 'core/hooks/redux';
import { useParams } from 'react-router-dom';
import {
  choosePhotosFinalLevel,
  getCustomer,
  getEnlargedPhoto,
} from 'modules/choosePhotoCustomer/store/actions';
import { Loader } from 'shared';
import { choosePhotoCustomerSlice } from 'modules/choosePhotoCustomer/store';
import { pink } from '@mui/material/colors';
import { ViewEnlargedPhoto } from 'shared/modal';

import { PhotoCard } from '../choosePhoto/PhotoCard';

export const FinalLevelContainer: FC = () => {
  const customer = useSelector(getCustomerSelector);
  const finalLevelData = useSelector(getFinalLevelData);
  const isLoading = useSelector(getIsLoadingFinalLevelSelector);
  const album = useSelector(getAlbumName);
  const levelsMap = useSelector(getLevelsMap);
  const enlargedPhoto = useSelector(getEnlargedPhotoSelector);
  const [openModal, setOpenModal] = useState(false);

  const dispatch = useAppDispatch();
  const params = useParams();

  const handleCloseViewEnlargedPhoto = (): void => {
    setOpenModal(false);
    dispatch(
      choosePhotoCustomerSlice.choosePhotoCustomerSlice.actions.resetEnlargedPhoto(),
    );
  };

  const handleOpenViewEnlargedPhoto = (id: number) => {
    dispatch(getEnlargedPhoto([id]));
    setOpenModal(Boolean(id));
  };

  useEffect(() => {
    if (params.albumGuid && params.customerId) {
      dispatch(choosePhotosFinalLevel(params.albumGuid));
      dispatch(getCustomer({ id: Number(params.customerId) }));
    }

    /** Удаляем все ранее сохраненные id фотографий из стора и localStorage */
    dispatch(
      choosePhotoCustomerSlice.choosePhotoCustomerSlice.actions.resetDataForCurrentLevel(),
    );
  }, []);

  return (
    <>
      {isLoading && <Loader />}

      <ViewEnlargedPhoto
        onClose={handleCloseViewEnlargedPhoto}
        open={openModal}
        enlargedPhoto={enlargedPhoto}
        setOpenModal={setOpenModal}
      />

      <Typography component={'div'} mb={'20px'}>
        Альбом: {album.albumName}
      </Typography>
      <Typography component={'div'} mb={'20px'}>
        На этой странице отображаются фото, которые вы выбрали по каждому из этапов. 
        </Typography>
      <Typography component={'div'} mb={'20px'}>
        Отобранные фото высланы вашему фотографу
      </Typography>
      <Typography component={'div'}>ФИО: {customer?.name}</Typography>
      {customer?.userChangedName && (
        <Typography mb={'20px'} component={'div'}>
          <Typography
            sx={{
              color: pink[800],
            }}
            component={'span'}
          >
            ФИО изменили на:{' '}
          </Typography>
          {customer?.userChangedName}
        </Typography>
      )}

      {finalLevelData &&
        Object.keys(finalLevelData)?.map((level) => {
          const levelData = finalLevelData[level];
          if (
            typeof levelData === 'object' &&
            levelData.trustExecutor === true
          ) {
            return (
              <>
                <Typography mt={'20px'} mb={'10px'} component={'div'} variant='h3'>
                  Этап: {levelsMap[Number(level)]}
                </Typography>
                <Typography
                  sx={{
                    color: pink[800],
                  }}
                  key={level}
                  component='div'
                  mb={'20px'}
                >
                  Вы доверили выбор фотографу
                </Typography>
              </>
            );
          } else {
            return (
              <Box mb={'30px'} key={level}>
                <Typography mb={'10px'} variant='h3'>
                  Этап: {levelsMap[Number(level)]} 
                </Typography>
                <Grid
                  sx={{
                    display: 'grid',
                    gap: 2,
                    gridTemplateColumns: 'repeat(auto-fill, 220px)',
                  }}
                >
                  {finalLevelData[level]?.map((item: any) => (
                    <PhotoCard
                      onOpenViewEnlargedPhoto={handleOpenViewEnlargedPhoto}
                      key={item.id}
                      item={item}
                    />
                  ))}
                </Grid>
              </Box>
            );
          }
        })}
    </>
  );
};
