import React from 'react';
import { Alert, Box } from '@mui/material';

import { FormConfirmationName } from './FormConfirmationName';

export const СonfirmationNameContainer = () => {
  return (
    <>
      <Alert severity='warning'>
        Подтвердите ФИО ребенка, так будет напечатано в вашем альбоме.
        <Box>
          {`Для исправления, введите верные фамилию и имя ниже. После нажатия
            кнопки "Подтвердить" результат сохраниться.`}
        </Box>
      </Alert>
      <FormConfirmationName />
    </>
  );
};
