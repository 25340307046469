import React from 'react';
import { ThemeProvider } from '@mui/material';
import { appTheme } from 'core/styles/appTheme';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
  ChoosePhoto,
  ChoosePhotoCustomer,
  FinalLevel,
  ViewingSelectedPhotos,
  СonfirmationName,
} from 'pages/choosePhotoCustomer';
import { TokenProvider } from 'utils/TokenProvider';
import { OpenAPI } from 'services/generated-api/core';
import { NotFound } from 'pages/notFound';

function App() {
  // eslint-disable-next-line no-undef
  OpenAPI.BASE = process.env.REACT_APP_BASE_URL || '';
  OpenAPI.TOKEN = TokenProvider.get() || undefined;

  return (
    <BrowserRouter basename='/'>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <ThemeProvider theme={appTheme}>
          <Routes>
            <Route
              path={`/:albumGuid`}
              element={<ChoosePhotoCustomer />}
              key='choose-photo-customer'
            />
            <Route
              path={`/confirmation-name/:albumGuid/:customerId`}
              element={<СonfirmationName />}
              key='confirmation-name'
            />
            <Route
              path={`/choose-photo/:albumGuid/:customerId`}
              element={<ChoosePhoto />}
              key='choose-photo'
            />
            <Route
              path={`/final-level/:albumGuid/:customerId`}
              element={<FinalLevel />}
              key='final-level'
            />
            <Route
              path={`/viewing-selected-photos/:albumGuid/:customerId`}
              element={<ViewingSelectedPhotos />}
              key='viewing-selected-photos'
            />
            <Route path={`*`} element={<NotFound />} key='notFound' />
          </Routes>
        </ThemeProvider>
      </SnackbarProvider>
    </BrowserRouter>
  );
}

export default App;
