import React, { useEffect, useState, FC } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate, useParams } from 'react-router-dom';
import {
  authCustomer,
  getCustomers,
} from 'modules/choosePhotoCustomer/store/actions';
import { useAppDispatch } from 'core/hooks/redux';
import { getCustomersSelector } from 'modules/choosePhotoCustomer/store/selectors';
import { Modal } from 'shared/modal';
import { SelectedCustomer } from 'modules/choosePhotoCustomer/models';

/**
 * Страница выбора фото для заказчика с этапами
 * @returns Страница выбора фото для заказчика с этапами
 */
export const SelectChild: FC = () => {
  const customers = useSelector(getCustomersSelector);

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selectChild, setSelectChild] = useState<SelectedCustomer | null>(null);
  const params = useParams();
  const dispatch = useAppDispatch();

  const handleNextStep = () => {
    const customerId = Number(selectChild?.id);
    customerId && dispatch(authCustomer({ customerId }));
    selectChild &&
      navigate(`/confirmation-name/${params.albumGuid}/${selectChild.id}`);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  useEffect(() => {
    params.albumGuid && dispatch(getCustomers({ albumGuid: params.albumGuid }));
  }, []);

  return (
    <>
      {selectChild && (
        <Modal
          title='Подтвердите, что вы правильно выбрали'
          open={open}
          content={selectChild?.label}
          onClose={() => {
            setOpen(false);
          }}
        >
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            Нет, я ошибся(лась)
          </Button>
          <Button onClick={handleNextStep}>Да, все верно</Button>
        </Modal>
      )}
      <Typography variant='h3' mb={'20px'}>
        Найдите в списке ФИО, выберите его и нажмите кнопку
        Выбрать
      </Typography>
      {customers && (
        <Autocomplete
          disablePortal
          id='combo-box-demo'
          options={customers}
          onChange={(event, newValue: SelectedCustomer | null) => {
            setSelectChild(newValue);
          }}
          sx={{ width: { sx: '100%', sm: '400px' } }}
          renderInput={(params) => (
            <TextField {...params} label='Выберите ФИО из списка' />
          )}
        />
      )}
      <Box sx={{ mt: '18px' }}>
        <Button
          disabled={!selectChild}
          onClick={handleOpenModal}
          variant='contained'
        >
          Выбрать
        </Button>
      </Box>
    </>
  );
};
