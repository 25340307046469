/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthResponse } from '../models/AuthResponse';
import type { LoginCustomerCommand } from '../models/LoginCustomerCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * Публичный метод для авторизации заказчика
     * @returns AuthResponse Success
     * @throws ApiError
     */
    public static postApiAuthLoginCustomer({
requestBody,
}: {
requestBody?: LoginCustomerCommand,
}): CancelablePromise<AuthResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Auth/login-customer',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
