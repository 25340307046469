import React, { FC, useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'core/hooks/redux';
import {
  getCustomerSelector,
  getDoneAllLevels,
} from 'modules/choosePhotoCustomer/store/selectors';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { TextInput } from 'shared';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  getCustomer,
  trustPhotographerAllPhotos,
  updateNameCustomer,
} from 'modules/choosePhotoCustomer/store/actions';
import { ChoiceCustomerOrPhotographer } from 'modules/choosePhotoCustomer/models';
import { validationSchema } from 'modules/choosePhotoCustomer/validationSchema';
import { choosePhotoCustomerSlice } from 'modules/choosePhotoCustomer/store';

export const FormConfirmationName: FC = () => {
  const customer = useSelector(getCustomerSelector);
  const doneAllLevels = useSelector(getDoneAllLevels);

  const [radioValue, setRadioValue] = useState<string | null>(null);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const handleSubmitForm = (data: FieldValues): void => {
    /** Отправляем завпрос на обновление имени, если имя обновили */
    if (customer && data.name !== customer.name) {
      dispatch(
        updateNameCustomer({
          name: data.name,
        }),
      );
    }
    /** Если пользователь выбрал хочу выбрать сам фото, то отправляем его на страницу выбора */
    if (radioValue === ChoiceCustomerOrPhotographer.Myself) {
      navigate(`/choose-photo/${params.albumGuid}/${params.customerId}`);
    }

    /** Если пользователь выбрал доверяю выбор фотографу,
     *  то отправляем отправялем информацию на сервер и игнорируем этапы выбра фотографий */
    if (
      radioValue === ChoiceCustomerOrPhotographer.Photographer &&
      params.albumGuid
    ) {
      dispatch(trustPhotographerAllPhotos(params.albumGuid));
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    params.customerId &&
      dispatch(getCustomer({ id: Number(params.customerId) }));

    return () => {
      dispatch(
        choosePhotoCustomerSlice.choosePhotoCustomerSlice.actions.resetCustomer(),
      );
    };
  }, [params.customerId]);

  /** Если пользователь выбрал доверяю выбор фотографу и информация сохранилась,
   * то перенаправляем на финальный шаг
   */
  useEffect(() => {
    if (doneAllLevels) {
      navigate(`/final-level/${params.albumGuid}/${params.customerId}`);
    }
  }, [doneAllLevels]);

  return (
    <Box component={'form'} onSubmit={handleSubmit(handleSubmitForm)}>
      <Box sx={{ width: { sx: '100%', sm: '500px' } }}>
        {customer ? (
          <TextInput
            label={'ФИО'}
            name={'name'}
            control={control as unknown as Control<FieldValues, any>}
            errors={errors}
            required
            defaultValue={customer.name}
          />
        ) : (
          <Skeleton height={58} />
        )}
      </Box>
      <Box sx={{ mt: '18px' }}>
        <Alert severity='warning'>{`Вы можете доверить выбор всех фотографий фотографу. Для этого выберите ниже пункт "Доверяю фотографу выбор всех фото"`}</Alert>
      </Box>
      <FormControl sx={{ mt: '18px' }}>
        <RadioGroup
          aria-labelledby='demo-radio-buttons-group-label'
          name='radio-buttons-group'
          value={radioValue}
          onChange={handleChange}
        >
          <FormControlLabel
            value={ChoiceCustomerOrPhotographer.Myself}
            control={<Radio />}
            label='Хочу сам выбрать все фото'
          />
          <FormControlLabel
            value={ChoiceCustomerOrPhotographer.Photographer}
            control={<Radio />}
            sx={{ marginTop: '20px'}}
            label='Доверяю фотографу выбор всех фото'
          />
        </RadioGroup>
      </FormControl>
      <Box sx={{ mt: '18px' }}>
        <Button
          disabled={!radioValue}
          type='submit'
          color={'primary'}
          variant='contained'
        >
          Подтвердить
        </Button>
        <Button onClick={handleGoBack} sx={{ ml: '18px' }} variant='outlined'>
          Назад
        </Button>
      </Box>
    </Box>
  );
};
