/* eslint-disable max-lines */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'core/store/setupStore';
import {
  UseAlbumResponseModel,
  UseAssetResponseModel,
  UseChoosedAssetsResponseModel,
  UseCustomerResponseModel,
  UseLevelResponseModel,
} from 'services/generated-api';

import { SelectedCustomer } from '../models';
import {
  albumAdapter,
  viewingSelectedPhotosAdapter,
} from './choosePhotoCustomerSlice';

export const getViewingSelectedPhotosSelector =
  viewingSelectedPhotosAdapter.getSelectors();

export const getAlbumSelector = albumAdapter.getSelectors();

/** Получение списка фотографий для просмотра в разделе "Добавленные"*/
export const getViewingSelectedPhotos = createSelector(
  (state: RootState) =>
    getViewingSelectedPhotosSelector.selectAll(
      state.choosePhotoCustomer.viewingSelectedPhotos.photos,
    ),
  (viewingSelectedPhotos) => viewingSelectedPhotos,
);

/** Получение всех заказчиков */
export const getCustomersSelector = createSelector(
  (state: RootState) => state.choosePhotoCustomer.customers,
  (customers): SelectedCustomer[] | null => {
    return (
      customers &&
      customers.map((customer) => ({
        id: customer.id as number,
        label: customer.name as string,
      }))
    );
  },
);

/** Опции для инфинит скролла */
export const getOptionsForInfiniteScroll = createSelector(
  (state: RootState) =>
    state.choosePhotoCustomer.viewingSelectedPhotos.hasNextPage,
  (state: RootState) =>
    state.choosePhotoCustomer.viewingSelectedPhotos.totalCount,

  (hasNextPage, totalCount: number | null) => {
    return { totalCount, hasNextPage };
  },
);

/** Получение всех заказчиков */
export const getCustomerSelector = createSelector(
  (state: RootState) => state.choosePhotoCustomer.customer,
  (customer): UseCustomerResponseModel | null => customer,
);

/** Получение списка фотографий и опций для отображения инфинит скрола */
export const getAlbum = createSelector(
  (state: RootState) => state.choosePhotoCustomer.album.totalCount,
  (state: RootState) => state.choosePhotoCustomer.album.hasNextPage,
  (state: RootState) => state.choosePhotoCustomer.album.pageNumber,
  (state: RootState) =>
    getAlbumSelector.selectAll(state.choosePhotoCustomer.album.photos),
  (totalCount, hasNextPage, pageNumber, photos) => {
    return { totalCount, hasNextPage, pageNumber, photos };
  },
);

/** Получение информации о следующем этапу */
export const getNextLevelSelector = createSelector(
  (state: RootState) => state.choosePhotoCustomer.nextLevel,
  (nextLevel): UseLevelResponseModel | null => nextLevel,
);
/** Блокируем скролл */
export const disableScrollingSelector = createSelector(
  (state: RootState) => state.choosePhotoCustomer.disableScrolling,
  (disableScrolling): boolean => disableScrolling,
);

/** Получение Ids выбранны фотографий*/
export const getSelectedId = createSelector(
  (state: RootState) => state.choosePhotoCustomer.selectedPhotoIds,
  (selectedPhotoIds): number[] => selectedPhotoIds,
);

/** Получение лимита по фоткам и количество уже вы,ранных  */
export const getSelectedOptions = createSelector(
  (state: RootState) => state.choosePhotoCustomer.selectedPhotoIds,
  (state: RootState) => state.choosePhotoCustomer.nextLevel,
  (
    selectedPhotoIds,
    nextLevel,
  ): {
    countSelectedPhoto: number;
    choosedPhotoCount: number | undefined;
    hasNextLevel: boolean | undefined;
  } => {
    return {
      countSelectedPhoto: selectedPhotoIds.length,
      choosedPhotoCount: nextLevel?.choosedPhotoCount,
      hasNextLevel: nextLevel?.hasNextLevel,
    };
  },
);

/** Получение данных для последнего этапа */
export const getFinalLevelData = createSelector(
  (state: RootState) => state.choosePhotoCustomer.choosePhotosFinalLevel,
  (finalLevelData) => {
    const copyFinalLevelData = finalLevelData && [...finalLevelData];
    const sortedData = copyFinalLevelData?.sort(
      (a, b) => (a as any).levelRate - (b as any).levelRate,
    );

    return sortedData?.reduce(
      (acc: any, item: UseChoosedAssetsResponseModel) => {
        if (item.levelId && !acc[item.levelId]) {
          acc[item.levelId] = [];
        }

        if (item.trustExecutor) {
          if (item.levelId) acc[item.levelId] = { trustExecutor: true };
        } else {
          item.levelId &&
            acc[item.levelId].push({
              fileName: item.assetFileName,
              fileBytes: item.assetFileBytes,
              description: item.assetDescription,
              id: item.assetId,
              trustExecutor: item.trustExecutor,
              levelRateRate: item.levelRate,
            });
        }
        return acc;
      },
      {},
    );
  },
);

/** Карта всех этапов */
export const getLevelsMap = createSelector(
  (state: RootState) => state.choosePhotoCustomer.choosePhotosFinalLevel,
  (choosePhotosFinalLevel) => {
    if (!choosePhotosFinalLevel) {
      return {};
    }

    return choosePhotosFinalLevel.reduce((result, item) => {
      if (item.levelId !== undefined) {
        result[item.levelId] = item.levelName || '';
      }
      return result;
    }, {} as { [key: number]: string });
  },
);

/** Загрузчик */
export const getIsLoading = createSelector(
  (state: RootState) => state.choosePhotoCustomer.isLoading,
  (isLoading) => isLoading,
);

/** получение выбранных фотографи, для финальной страницы  */
export const getAlbumName = createSelector(
  (state: RootState) => state.choosePhotoCustomer.choosePhotosFinalLevel,
  (choosePhotosFinalLevel) => {
    const albumName =
      choosePhotosFinalLevel?.find((item) => item.albumName)?.albumName || '';
    const album = { albumName: albumName };
    return album;
  },
);

/** Получение всех этапов */
export const getLevelSelector = createSelector(
  (state: RootState) => state.choosePhotoCustomer.levels,
  (levels): UseLevelResponseModel[] | null => levels,
);

/** Получение флага о завершение всех этапах */
export const getDoneAllLevels = createSelector(
  (state: RootState) => state.choosePhotoCustomer.doneAllLevels,
  (doneAllLevels): boolean => doneAllLevels,
);

/** Получение статуса чекбокса о выборе пользователем "добверяю выбор фотографу" на этапах выбора фотографий */
export const getCheckedTrustLevelExecutor = createSelector(
  (state: RootState) => state.choosePhotoCustomer.checkedTrustLevelExecutor,
  (checkedTrustLevelExecutor): boolean => checkedTrustLevelExecutor,
);

/** Получение информации об альбоме */
export const getAlbumInfoSelector = createSelector(
  (state: RootState) => state.choosePhotoCustomer.albumInfo,
  (albumInfo): UseAlbumResponseModel | null => albumInfo,
);

/** Получение ошибок запроса */
export const getErrors = createSelector(
  (state: RootState) => state.choosePhotoCustomer.errors,
  (errors): null | { name: string; message: string } => errors,
);

/** Получение увеличенного изображения */
export const getEnlargedPhotoSelector = createSelector(
  (state: RootState) => state.choosePhotoCustomer.enlargedPhoto.photo,
  (enlargedPhoto): null | UseAssetResponseModel =>
    enlargedPhoto && enlargedPhoto[0],
);

/** Получение закрузчика для финальной страницы */
export const getIsLoadingEnlargedPhoto = createSelector(
  (state: RootState) => state.choosePhotoCustomer.enlargedPhoto.isLoading,
  (isLoading): boolean => isLoading,
);

/** Получение закрузчика для финальной страницы */
export const getIsLoadingFinalLevelSelector = createSelector(
  (state: RootState) => state.choosePhotoCustomer.isLoadingFinalLevel,
  (isLoadingFinalLevel): boolean => isLoadingFinalLevel,
);
