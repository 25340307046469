import { combineReducers } from '@reduxjs/toolkit';
import { reducers as choosePhotoCustomer } from 'modules/choosePhotoCustomer/store';

import { reducers as notificationSlice } from './notification';

export function createReducer() {
  return combineReducers({
    ...notificationSlice,
    ...choosePhotoCustomer,
  });
}

export const rootReducer = createReducer();
