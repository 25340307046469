import React from 'react';
import {
  ChooseChildContainer,
  ChoosePhotoCustomerLayout,
} from 'modules/choosePhotoCustomer';

/**
 * Страница выбора фото для заказчика с этапами
 * @returns Страница выбора фото для заказчика с этапами
 */
export const ChoosePhotoCustomer = () => {
  return (
    <ChoosePhotoCustomerLayout>
      <ChooseChildContainer />
    </ChoosePhotoCustomerLayout>
  );
};
