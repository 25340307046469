import React, { FC, ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { useAppDispatch } from 'core/hooks/redux';
import { getNotification } from 'core/store/notification/selectors';
import { notificationSlice } from 'core/store/notification';

type Props = {
  /** контент сайта */
  children: ReactNode;
};

/**
 * Обертка над компонентами. Которая отображает уведомления
 * @param props пропсы компонента
 * @returns Обертка над компонентами. Которая отображает уведомления
 */
export const WrapperNotification: FC<Props> = (props) => {
  const { notification } = useSelector(getNotification);
  const dispatch = useAppDispatch();

  /** Отображение уведомлений */
  useEffect(() => {
    let isSubscribed = true;
    setTimeout(() => {
      if (notification.message && notification.variant) {
        enqueueSnackbar(notification.message, {
          variant: notification.variant,
        });
        dispatch(notificationSlice.notificationSlice.actions.resetState());
      }
    }, 0);
    return () => {
      isSubscribed = false;
    };
  }, [notification]);

  return <>{props.children}</>;
};
