import React from 'react';
import {
  ChoosePhotoCustomerLayout,
  FinalLevelContainer,
} from 'modules/choosePhotoCustomer';

/**
 * Финальная страница всех этапов. На ней выводятся все выбранные фотографии этапов
 * @returns  Финальная страница всех этапов
 */
export const FinalLevel = () => {
  return (
    <ChoosePhotoCustomerLayout>
      <FinalLevelContainer />
    </ChoosePhotoCustomerLayout>
  );
};
