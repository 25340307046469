import React from 'react';
import { Box, Container, Typography } from '@mui/material';
// import { FullLayout } from 'core';

export const NotFound = () => {
  return (
    <Box
      component='main'
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        minHeight: '100%',
      }}
    >
      <Container maxWidth='md'>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              mb: 3,
              textAlign: 'center',
            }}
          >
            <img
              alt='Under development'
              src='/img/errors/error-404.png'
              style={{
                display: 'inline-block',
                maxWidth: '100%',
                width: 400,
              }}
            />
          </Box>
          <Typography align='center' sx={{ mb: 3 }} variant='h3'>
            404: Страница не найдена
          </Typography>          
        </Box>
      </Container>
    </Box>
  );
};
