import { createSlice } from '@reduxjs/toolkit';
import { NotificationOptions } from 'core/models';

export interface NotificationState {
  notification: NotificationOptions;
}

export const initialState: Readonly<NotificationState> = {
  notification: {
    variant: null,
    message: null,
  },
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    pushNotification: (state, { payload }) => {
      state.notification = {
        variant: payload.variant,
        message: payload.message,
      };
    },
    resetState: (state) => {
      state.notification = {
        variant: null,
        message: null,
      };
    },
  },
});
export default notificationSlice.reducer;
