import React, { FC } from 'react';
import FsLightbox from 'fslightbox-react';

import { UseAssetResponseModel } from 'services/generated-api';
import { useAppDispatch } from 'core/hooks/redux';
import { choosePhotoCustomerSlice } from 'modules/choosePhotoCustomer/store';
import { Loader } from 'shared/loader';
import { useSelector } from 'react-redux';
import { getIsLoadingEnlargedPhoto } from 'modules/choosePhotoCustomer/store/selectors';

export type Props = {
  enlargedPhoto: UseAssetResponseModel | null;
  open: boolean;
  onClose: () => void;
  setOpenModal?: (toggle: boolean) => void;
};
export const ViewEnlargedPhoto: FC<Props> = (props) => {
  const isLoading = useSelector(getIsLoadingEnlargedPhoto);
  const dispatch = useAppDispatch();

  return (
    <>
      {props.open && (
        <>
          {isLoading && <Loader />}
          <FsLightbox
            toggler={Boolean(props?.enlargedPhoto?.fileBytes)}
            onClose={() => {
              props.setOpenModal && props.setOpenModal(false);
              dispatch(
                choosePhotoCustomerSlice.choosePhotoCustomerSlice.actions.resetEnlargedPhoto(),
              );
            }}
            sources={[
              <img
                key={1}
                src={`data:image/jpg;base64, ${props?.enlargedPhoto?.fileBytes}`}
              />,
            ]}
          />
        </>
      )}
    </>
  );
};
