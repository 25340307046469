import React from 'react';
import { AppBar, Box, Button, Container, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import {
  getCheckedTrustLevelExecutor,
  getNextLevelSelector,
  getSelectedOptions,
} from 'modules/choosePhotoCustomer/store/selectors';
import { useAppDispatch } from 'core/hooks/redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  saveChoosePhotoIds,
  trustPhotographerPhotosForLevel,
} from 'modules/choosePhotoCustomer/store/actions';
import { choosedPhotoCountGenerateString } from 'modules/choosePhotoCustomer/utils';

export const ChoosePhotoCustomerHeader = () => {
  const selectedOptions = useSelector(getSelectedOptions);
  const checkedTrustLevelExecutor = useSelector(getCheckedTrustLevelExecutor);
  const nextLevelSelector = useSelector(getNextLevelSelector);

  const dispatch = useAppDispatch();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const regexViewingSelectedPhotos = /viewing-selected-photos/;
  const regexFinalLevel = /final-level/;
  /** Если страница просмотра выбранных фотографий,
   *  то скрываем кнопку для перехода на эту страницу и выводим кнопку назад
   */
  const hasViewingSelectedPhotosPage = regexViewingSelectedPhotos.test(
    location.pathname,
  );
  const hasFinalLevelPage = regexFinalLevel.test(location.pathname);

  /** если лимит фоток -1, то можно выбрать любое количество фото и кнопка должна отображаться
   * если больше 0, то тогда нужно сравнивать с количеством фотографий для этапа с количеством выбранных
   */
  const visibleButtonNextLevel =
    checkedTrustLevelExecutor ||
    selectedOptions.choosedPhotoCount === -1 ||
    selectedOptions.choosedPhotoCount === selectedOptions.countSelectedPhoto;

  const visibleGoToViewingSelectedPhotosButton = Boolean(
    selectedOptions.countSelectedPhoto,
  );

  /** Переходим на следующий этап выбора
   * @returns void
   */
  const handleNextLevel = () => {
    if (params.albumGuid && !checkedTrustLevelExecutor) {
      dispatch(saveChoosePhotoIds(params.albumGuid));
      /** Если мы находимся на странице просмотра выбранных фотографий и нажимаем перейти на следующий этап,
       * то нам нужно сделать редирект на страницу этапа (выбор фотографий)
       */
      hasViewingSelectedPhotosPage && navigate(-1);
    }

    if (
      nextLevelSelector?.id &&
      params.albumGuid &&
      checkedTrustLevelExecutor
    ) {
      dispatch(
        trustPhotographerPhotosForLevel({
          albumGuid: params.albumGuid,
          levelId: nextLevelSelector.id,
        }),
      );
    }
  };

  const handleGoToViewingSelectedPhotos = () => {
    if (params.albumGuid) {
      navigate(
        `/viewing-selected-photos/${params.albumGuid}/${params.customerId}`,
      );
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  /** Формирует строку сколько нужно выбрать для перехода на следующий этап */
  const choosedPhotoCountString = choosedPhotoCountGenerateString(
    selectedOptions.choosedPhotoCount,
  );

  return (
    <>
      <AppBar
        sx={{
          height: '64px',
          position: 'fixed',
          /** по умолчанию в настройках mui стоит flexDirection: column  */
          justifyContent: 'center',
        }}
      >
        <Container sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box
              component="img"
              sx={{
              height: 30,
              mr: 2 
              }}
              alt="logo"
              src="/logo192.png"
          />
          <Typography
            component='h1'
            variant='h6'
            color='inherit'
            noWrap
            sx={{ flexGrow: 1}}
          >
            gallery-photo.ru
          </Typography>
          <Button
            disabled={hasFinalLevelPage || !visibleButtonNextLevel}
            onClick={handleNextLevel}
            variant='contained'
            color='error'
            sx={{ marginRight: '12px', display: { xs: 'none', sm: 'block' }  }}
          >
            Перейти на следующий этап
          </Button>
          {hasViewingSelectedPhotosPage ? (
            <Button 
              onClick={handleGoBack} 
              variant='outlined' 
              color='inherit'
              sx={{ display: { xs: 'none', sm: 'block' }  }}>
              Назад
            </Button>
          ) : (
            <Button
              onClick={handleGoToViewingSelectedPhotos}
              variant='outlined'
              color='inherit'
              disabled={!visibleGoToViewingSelectedPhotosButton}
              sx={{  display: { xs: 'none', sm: 'block' }  }}
            >
              Посмотреть выбранные {selectedOptions.countSelectedPhoto}
              {choosedPhotoCountString}
            </Button>
          )}
        </Container>
      </AppBar>
    </>
  );
};
