import React, { FC, useEffect } from 'react';
import Parser from 'html-react-parser';
import { Skeleton, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAlbumInfo } from 'modules/choosePhotoCustomer/store/actions';
import { getAlbumInfoSelector } from 'modules/choosePhotoCustomer/store/selectors';
import { useAppDispatch } from 'core/hooks/redux';

/**
 * Блок выбора Заказчика
 * @returns Блок выбора Заказчика
 */
export const ChooseChildInfo: FC = () => {
  const albumInfo = useSelector(getAlbumInfoSelector);

  const dispatch = useAppDispatch();
  const params = useParams();

  useEffect(() => {
    if (params.albumGuid) dispatch(getAlbumInfo(params.albumGuid));
  }, []);

  return (
    <>
      <Typography variant='h3' mb={'20px'}>
        Альбом: {albumInfo?.name}
      </Typography>
      {albumInfo?.description ? (
        <Typography component='div' mb={'20px'}>
          {Parser(albumInfo?.description)}
        </Typography>
      ) : (
        <Skeleton height={42} />
      )}
    </>
  );
};
