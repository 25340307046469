import React, { FC } from 'react';

import { ChooseChildInfo } from './ChooseChildInfo';
import { SelectChild } from './SelectChild';

export const ChooseChildContainer: FC = () => {
  return (
    <>
      <ChooseChildInfo />
      <SelectChild />
    </>
  );
};
