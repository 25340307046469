/* eslint-disable max-lines */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PAGE_SIZE } from 'core/consts';
import { RootState } from 'core/store/setupStore';
import {
  AuthService,
  LoginCustomerCommand,
  UpdateCustomerNameCommand,
  UseAssetsService,
  UseLevelResponseModel,
  CustomerAlbumTrustExecutorCommand,
  CustomerLevelTrustExecutorCommand,
  UseCustomerResponseModelPaginatedList,
  UseCustomerResponseModel,
  UseAssetResponseModelPaginatedList,
  UseChoosedAssetsResponseModel,
  UseAlbumResponseModel,
  ImageSize,
} from 'services/generated-api';
import { TokenProvider } from 'utils/TokenProvider';

import { choosePhotoCustomerSlice } from 'modules/choosePhotoCustomer/store';
import { notificationSlice } from 'core/store/notification';
import { NotificationVariant } from 'core/models';

/** Получение всех заказчиков */
export const getCustomers = createAsyncThunk<
  UseCustomerResponseModelPaginatedList,
  { albumGuid: string }
>(
  'choosePhotoCustomer/getCustomers',
  async ({ albumGuid }, { rejectWithValue }) => {
    try {
      const response = await UseAssetsService.getApiUseAssetsCustomers({
        albumGuid,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/** Получение одного заказчика */
export const getCustomer = createAsyncThunk<
  UseCustomerResponseModel,
  { id: number }
>('choosePhotoCustomer/getCustomer', async ({ id }, { rejectWithValue }) => {
  try {
    const response = await UseAssetsService.getApiUseAssetsCustomer({
      id,
    });

    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

/** Редактирование одного заказчика */
export const updateNameCustomer = createAsyncThunk<
  boolean,
  UpdateCustomerNameCommand
>(
  'choosePhotoCustomer/updateNameCustomer',
  async (requestBody, { rejectWithValue }) => {
    try {
      const response = await UseAssetsService.putApiUseAssetsChangeCustomerName(
        {
          requestBody,
        },
      );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/** Получение списка фотографий для следующего этапа */
export const getAlbums = createAsyncThunk<
  UseAssetResponseModelPaginatedList,
  {
    levelId?: number;
    pageSize: number;
    pageNumber: number;
    ids?: number[];
  }
>(
  'choosePhotoCustomer/getAlbums',
  async ({ levelId, pageSize, pageNumber, ids }, { rejectWithValue }) => {
    try {
      const response = await UseAssetsService.getApiUseAssetsAssets({
        levelId,
        pageSize,
        pageNumber,
        ids,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/** Получение списка фотографий, при скроле страницы */
export const getAlbumsInfinity = createAsyncThunk<
  UseAssetResponseModelPaginatedList,
  {
    levelId?: number;
    pageSize: number;
    pageNumber: number;
    ids?: number[];
  }
>(
  'choosePhotoCustomer/getAlbumsInfinity',
  async ({ levelId, pageSize, pageNumber, ids }, { rejectWithValue }) => {
    try {
      const response = await UseAssetsService.getApiUseAssetsAssets({
        levelId,
        pageSize,
        pageNumber,
        ids,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/** Получение списка фотографий для страницы с добавленными для просмотра */
export const getAlbumsForViewingSelectedPhotos = createAsyncThunk<
  UseAssetResponseModelPaginatedList,
  {
    levelId?: number;
    pageSize: number;
    pageNumber: number;
    ids?: number[];
  }
>(
  'choosePhotoCustomer/getAlbumsForViewingSelectedPhotos',
  async ({ levelId, pageSize, pageNumber, ids }, { rejectWithValue }) => {
    try {
      const response = await UseAssetsService.getApiUseAssetsAssets({
        levelId,
        pageSize,
        pageNumber,
        ids,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/** Получение следующего этапа */
export const nextLevelChoosePhoto = createAsyncThunk<
  UseLevelResponseModel,
  string
>(
  'choosePhotoCustomer/nextLevelChoosePhoto',
  async (albumGuid, { dispatch, rejectWithValue }) => {
    try {
      const response = await UseAssetsService.getApiUseAssetsNextLevel({
        albumGuid,
      });
      if (response.id) {
        dispatch(
          getAlbums({
            levelId: response.id,
            pageSize: PAGE_SIZE,
            pageNumber: 1,
          }),
        );
      }

      return response;
    } catch (error) {
      dispatch(
        notificationSlice.notificationSlice.actions.pushNotification({
          variant: NotificationVariant.Error,
          message:
            'Все этапы пройдены. Вы будете перенаправлены на страницу просмотра фотографий',
        }),
      );

      return rejectWithValue(error);
    }
  },
);

/** Авторизуемся как заказчик */
export const authCustomer = createAsyncThunk<undefined, LoginCustomerCommand>(
  'choosePhotoCustomer/authCustomer',
  async (requestBody, { rejectWithValue }) => {
    try {
      const response = await AuthService.postApiAuthLoginCustomer({
        requestBody,
      });
      if (response.token) TokenProvider.set(response.token);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/** Отправляем запрос на сервер и сообщаем о завршении этапа,
 *  после того как были сохранены все выбранные фотографии текущего этапа
 */
export const levelCompleted = createAsyncThunk<boolean | undefined, string>(
  'choosePhotoCustomer/levelCompleted',
  async (albumGuid, { rejectWithValue, getState, dispatch }) => {
    /** Удаляем все ранее сохраненные id фотографий из стора и localStorage */
    dispatch(
      choosePhotoCustomerSlice.choosePhotoCustomerSlice.actions.resetDataForCurrentLevel(),
    );

    /** меняем значение чекбокса на false */
    dispatch(
      choosePhotoCustomerSlice.choosePhotoCustomerSlice.actions.setCheckedTrustLevelExecutor(
        false,
      ),
    );
    /** Блокируем скролл */
    dispatch(
      choosePhotoCustomerSlice.choosePhotoCustomerSlice.actions.disableScrolling(),
    );
    const state = getState();
    const levelId = (state as RootState).choosePhotoCustomer?.nextLevel?.id;
    const hasNextLevel = (state as RootState).choosePhotoCustomer?.nextLevel
      ?.hasNextLevel;

    const requestBody = {
      levelId,
      isComplited: true,
    };

    try {
      const response = await UseAssetsService.postApiUseAssetsLevelComplite({
        requestBody,
      });
      /** Если запрос прошел успешно и этап завершен, и есть следующий этап hasNextLevel,
       *  то отправляем цепочку запросов для получение информации о следующем этапе,
       *  и получение новых фотографий, для нового этапа
       */
      if (response && hasNextLevel) {
        dispatch(nextLevelChoosePhoto(albumGuid));
      }

      /** Если запрос прошел успешно и этап завершен, и нет следующего этап hasNextLevel === false,
       *  меняем в сторе флаг doneAllLevels для того,
       * чтобы сработал переход на финальная страницу просмотра фотографий
       */
      if (response && !hasNextLevel) {
        return true;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/** Сохраняем на сервере выбранные на одном из этапов фотографии  */
export const saveChoosePhotoIds = createAsyncThunk<undefined, string>(
  'choosePhotoCustomer/saveChoosePhotoIds',
  async (albumGuid, { rejectWithValue, getState, dispatch }) => {
    const state = getState();
    const assetIds = (state as RootState).choosePhotoCustomer?.selectedPhotoIds;
    const requestBody = {
      assetIds,
    };

    try {
      const response = await UseAssetsService.postApiUseAssetsChooseArray({
        requestBody,
      });

      /** Если запрос прошел успешно и фотогафии сохранены, то отправляем запрос на завершение этапа */
      if (response) {
        dispatch(levelCompleted(albumGuid));
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/** Запрос на получение всех вибранных фотографий на всех этапах */
export const choosePhotosFinalLevel = createAsyncThunk<
  UseChoosedAssetsResponseModel[],
  string
>(
  'choosePhotoCustomer/choosePhotosFinalLevel',
  async (albumGuid, { rejectWithValue }) => {
    try {
      const response = await UseAssetsService.getApiUseAssetsChoosed({
        albumGuid,
        includeFiles: true,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/** Запрос на получение всех этопов */
export const getLevels = createAsyncThunk<UseLevelResponseModel[], string>(
  'choosePhotoCustomer/getLevels',
  async (albumGuid, { rejectWithValue }) => {
    try {
      const response = await UseAssetsService.getApiUseAssetsLevels({
        albumGuid,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/** Отправляем информацию о том что пользователь не будет выбирать фотографии и доверяет выбор фотографу    */
export const trustPhotographerAllPhotos = createAsyncThunk<boolean, string>(
  'choosePhotoCustomer/trustPhotographerAllPhotos',
  async (albumGuid, { rejectWithValue }) => {
    const requestBody: CustomerAlbumTrustExecutorCommand = {
      albumGuid: albumGuid,
      trustExecutor: true,
    };
    try {
      const response =
        await UseAssetsService.postApiUseAssetsTrustAlbumExecutor({
          requestBody,
        });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/** Отправляем информацию о том что пользователь не будет выбирать фотографии на текужем этапе
 *  и доверяет выбор фотографу
 */
export const trustPhotographerPhotosForLevel = createAsyncThunk<
  undefined,
  { levelId: number; albumGuid: string }
>(
  'choosePhotoCustomer/trustPhotographerPhotosForLevel',
  async (params, { dispatch, rejectWithValue }) => {
    const requestBody: CustomerLevelTrustExecutorCommand = {
      levelId: params.levelId,
      trustExecutor: true,
    };
    try {
      const response =
        await UseAssetsService.postApiUseAssetsTrustLevelExecutor({
          requestBody,
        });

      /** Если запрос прошел успешно и фотогафии сохранены, то отправляем запрос на завершение этапа */
      if (response) {
        dispatch(levelCompleted(params.albumGuid));
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/** Получение информации об альбоме */
export const getAlbumInfo = createAsyncThunk<UseAlbumResponseModel, string>(
  'choosePhotoCustomer/getAlbumInfo',
  async (guid, { rejectWithValue }) => {
    try {
      const response = await UseAssetsService.getApiUseAssetsAlbum({ guid });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

/** Получение увеличенную фотку */
export const getEnlargedPhoto = createAsyncThunk<
  UseAssetResponseModelPaginatedList,
  number[]
>('choosePhotoCustomer/getEnlargedPhoto', async (ids, { rejectWithValue }) => {
  try {
    const response = await UseAssetsService.getApiUseAssetsAssets({
      ids,
      imageSize: ImageSize.ORIGINAL,
    });

    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});
