import React from 'react';
import { AppBar, BottomNavigation, BottomNavigationAction, Box, Button, Container, Paper, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import {
  getCheckedTrustLevelExecutor,
  getNextLevelSelector,
  getSelectedOptions,
} from 'modules/choosePhotoCustomer/store/selectors';
import { useAppDispatch } from 'core/hooks/redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  saveChoosePhotoIds,
  trustPhotographerPhotosForLevel,
} from 'modules/choosePhotoCustomer/store/actions';
import { choosedPhotoCountGenerateString } from 'modules/choosePhotoCustomer/utils';
import QueuePlayNextIcon from '@mui/icons-material/QueuePlayNext';
import ChecklistIcon from '@mui/icons-material/Checklist';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const ChoosePhotoCustomerFooter = () => {
  const selectedOptions = useSelector(getSelectedOptions);
  const checkedTrustLevelExecutor = useSelector(getCheckedTrustLevelExecutor);
  const nextLevelSelector = useSelector(getNextLevelSelector);

  const dispatch = useAppDispatch();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const regexViewingSelectedPhotos = /viewing-selected-photos/;
  const regexFinalLevel = /final-level/;
  /** Если страница просмотра выбранных фотографий,
   *  то скрываем кнопку для перехода на эту страницу и выводим кнопку назад
   */
  const hasViewingSelectedPhotosPage = regexViewingSelectedPhotos.test(
    location.pathname,
  );
  const hasFinalLevelPage = regexFinalLevel.test(location.pathname);

  /** если лимит фоток -1, то можно выбрать любое количество фото и кнопка должна отображаться
   * если больше 0, то тогда нужно сравнивать с количеством фотографий для этапа с количеством выбранных
   */
  const visibleButtonNextLevel =
    checkedTrustLevelExecutor ||
    selectedOptions.choosedPhotoCount === -1 ||
    selectedOptions.choosedPhotoCount === selectedOptions.countSelectedPhoto;

  const visibleGoToViewingSelectedPhotosButton = Boolean(
    selectedOptions.countSelectedPhoto,
  );

  /** Переходим на следующий этап выбора
   * @returns void
   */
  const handleNextLevel = () => {
    if (params.albumGuid && !checkedTrustLevelExecutor) {
      dispatch(saveChoosePhotoIds(params.albumGuid));
      /** Если мы находимся на странице просмотра выбранных фотографий и нажимаем перейти на следующий этап,
       * то нам нужно сделать редирект на страницу этапа (выбор фотографий)
       */
      hasViewingSelectedPhotosPage && navigate(-1);
    }

    if (
      nextLevelSelector?.id &&
      params.albumGuid &&
      checkedTrustLevelExecutor
    ) {
      dispatch(
        trustPhotographerPhotosForLevel({
          albumGuid: params.albumGuid,
          levelId: nextLevelSelector.id,
        }),
      );
    }
  };

  const handleGoToViewingSelectedPhotos = () => {
    if (params.albumGuid) {
      navigate(
        `/viewing-selected-photos/${params.albumGuid}/${params.customerId}`,
      );
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  /** Формирует строку сколько нужно выбрать для перехода на следующий этап */
  const choosedPhotoCountString = choosedPhotoCountGenerateString(
    selectedOptions.choosedPhotoCount,
  );

  return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, display: { xs: 'block', sm: 'none' } }} 
        elevation={3}>
          <BottomNavigation showLabels sx={{ width: '100%' }} >
              {(hasFinalLevelPage || !visibleButtonNextLevel)  ? (
                  <BottomNavigationAction
                      disabled={hasFinalLevelPage || !visibleButtonNextLevel}
                      onClick={handleNextLevel}
                      label="Перейти на следующий этап"
                      value="disabled"
                      sx={{background: "#c3c3c3", color: 'white'}}
                      icon={<QueuePlayNextIcon /> }
                      />
              ):(
                  <BottomNavigationAction
                  onClick={handleNextLevel}
                  label="Перейти на следующий этап"
                  value="recents"
                  sx={{background: "#d32f2f", color: 'white'}}
                  icon={<QueuePlayNextIcon /> }
                />
              )}
  
            {hasViewingSelectedPhotosPage ? (
               <BottomNavigationAction
                       onClick={handleGoBack}
                        label="Назад"
                        value="back"
                        sx={{background: "#5f5f5f", color: 'white'}}
                        icon={<ArrowBackIcon />}
                      />
            ) : (

                (!visibleGoToViewingSelectedPhotosButton)  ? (
                <BottomNavigationAction
                    disabled={hasFinalLevelPage || !visibleButtonNextLevel}
                    onClick={handleGoToViewingSelectedPhotos}
                    label="Посмотреть выбранные"
                    value="disabled"
                    sx={{background: "#c3c3c3" , color: 'white'}}
                    icon={<ChecklistIcon />}      
                >
                  Посмотреть выбранные {selectedOptions.countSelectedPhoto}
                    {choosedPhotoCountString}
                </BottomNavigationAction>

                ) : (
                <BottomNavigationAction
                    disabled={!visibleGoToViewingSelectedPhotosButton}
                    onClick={handleGoToViewingSelectedPhotos}
                    label="Посмотреть выбранные"
                    value="favorites"
                    sx={{background: "#2e7d32" , color: 'white'}}
                    icon={<ChecklistIcon />}      
                >
                  Посмотреть выбранные {selectedOptions.countSelectedPhoto}
                    {choosedPhotoCountString}
                </BottomNavigationAction>
                )

              
            )}
          </BottomNavigation>
        </Paper>
    
    
  );
};
